<template>
  <div class="d-inline-block">
    <b-button class="mr-1" v-for="p in formPreset" :key="p._id" :variant="p.btnType"
              @click="editMode ? show({_id: p._id}) : setForm(p.form, $event)">{{ p.btnName }}
      <i v-if="editMode" class="fa fa-pencil"></i>
    </b-button>
    <b-dropdown variant="light" right no-caret>
      <template v-slot:button-content>
        <i class="fa fa-gear"></i>
      </template>
      <b-dropdown-item @click="show()"><i class="fa fa-plus"></i> Form Preset 추가</b-dropdown-item>
      <b-dropdown-item v-if="$R('META_W')" :active="editMode" @click="editMode = !editMode">
        <i class="fa fa-pencil"></i> Form Preset 수정모드
      </b-dropdown-item>
    </b-dropdown>

    <b-modal title="검색조건(Form) Preset 설정" size="lg" v-model="modal.preset" ok-title="저장">
      <h5 v-if="presetModal._id">기존에 저장된 검색조건을 변경합니다</h5>
      <h5 v-else>현재 설정된 검색조건을 저장합니다</h5>

      <b-row>
        <b-col sm="8">
          <div class="mt-2">
            <div class="label-sm">이름을 선택해주세요</div>
            <b-input v-model="presetModal.btnName"></b-input>
          </div>

          <div class="mt-2">
            <div class="label-sm">버튼 색상을 선택해주세요</div>
            <b-button class="mr-1 mb-1" size="sm" v-for="c in ['primary','secondary','success','warning','danger','info','light','dark']" :key="c"
                      :variant="c" @click="setBtnType(c)">{{ c }}
            </b-button>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="mt-2">
            <div class="label-sm">미리보기</div>
            <b-button class="" :variant="presetModal.btnType">{{ presetModal.btnName }}</b-button>
          </div>
        </b-col>
      </b-row>

      <div class="mt-2">
        <div class="label-sm">
          검색조건을 확인하시고 필요시 수정해주세요. JSON 형식에 맞게 입력해주세요.
          <span class="text-primary pointer" @click="copyFromMain()">현재 검색조건(Form) 가져오기</span>
        </div>
        <b-textarea v-model="presetModal.form" rows="15"></b-textarea>
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: presetModal})">
          JSON
        </b-button>
        <b-button v-if="presetModal._id" variant="success" @click="updatePreset">
          수정
        </b-button>
        <b-button v-else variant="primary" @click="addPreset">
          저장
        </b-button>
        <b-button v-if="presetModal._id" variant="danger" @click="deletePreset">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "FormPreset",
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    defaultForm: Object,
    page: String,
  },
  data() {
    return {
      formPreset: [],
      presetModalBase: {
        _id: '',
        form: '',
        btnName: 'Form',
        btnType: 'light',
      },
      presetModal: {},
      editMode: false,
      compress: false,
      selectedIdsText: '',
      input_target: 'main',
      modal: {preset: false, input: false, help: false},

    }
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  async created() {
    this.getFormPreset();
  },
  methods: {
    async getFormPreset() {
      // TODO: 동일 페이지의 여러 곳에 form preset 이 있을 때 싱크가 일치하지 않는 문제 해결
      // 공통: 각 preset 로드를 page 별로 queue 에 넣고 약간의 timeout 후 1회만 가져와서 분배
      // 1안: 데이터 원천을 공유형으로 (user 등)
      // 2안: 데이터의 갱신이 있을 때 event 발행하여 싱크
      const j = await this.$api.getJson(`/meta/formPreset?page=${encodeURIComponent(this.page)}`);
      if (j) {
        j.list.forEach(e => {
          e.form = JSON.parse(e.form);
        });
        this.formPreset = j.list;
      } else {
        this.formPreset = [];
      }
    },
    /**
     * 프리셋을 검색폼에 적용한다.
     */
    setForm(form) {
      this.$emit('resetForm');
      // resetForm 을 하게 되면 this.form 으로 전달되는 링크가 끊기고 갱신되며, 그 객체를 바로 쓸 수는 없다.
      // this.form !== this.form(in nextTick)
      // const oldForm = this.form;
      this.$nextTick(() => {
        // console.log('oldForm === this.form', oldForm === this.form);
        for (const [k, v] of Object.entries(form)) {
          if (k === 'fields' && this.form.fields) {
            for (const [key, obj] of Object.entries(v)) {
              Object.assign(this.form.fields[key], obj);
            }
          } else if (k === 'incExc' && this.form.incExc) {
            for (const [key, obj] of Object.entries(v)) {
              Object.assign(this.form.incExc[key], obj);
            }
          } else {
            this.form[k] = this.$utils.clone(v);
          }
        }
        this.$emit('change', this.form);
      });
    },
    getCurrentForm() {
      // 각 항목이 default 와 같다면 제외한다. JSON 화 시켜서 비교한다.
      // fields 와 incExc 는 특수처리가 필요하다.
      const form = {};
      for (const [k, v] of Object.entries(this.form)) {
        if (JSON.stringify(v) !== JSON.stringify(this.defaultForm[k])) {
          if (k === 'fields') {
            const fields = {};
            for (const [key, obj] of Object.entries(v)) {
              if (obj.op.in('eq', 'ne', 'like') && obj.value !== '') {
                fields[key] = {op: obj.op, value: obj.value};
              } else if (obj.op === 'enum' && obj.values.length > 0) {
                fields[key] = {op: obj.op, values: obj.values};
              } else if (obj.op === 'range' && (obj.gte !== '' || obj.lte !== '')) {
                fields[key] = {op: obj.op, gte: obj.gte, lte: obj.lte};
              } else if (obj.op === 'exists') {
                fields[key] = {op: obj.op, exists: obj.exists};
              }
            }
            if (Object.keys(fields).length) {
              form.fields = fields;
            }
          } else if (k === 'incExc') {
            const incExc = {};
            for (const [key, obj] of Object.entries(v)) {
              if (obj.include || obj.exclude) {
                incExc[key] = obj;
              }
            }
            if (Object.keys(incExc).length) {
              form.incExc = incExc;
            }
          } else {
            form[k] = v;
          }
        }
      }
      return form;
    },
    show(params) {
      if (params && params._id) {
        const preset = this.$utils.clone(this.formPreset.find(e => e._id === params._id));
        preset.form = JSON.stringify(preset.form, null, 2);
        this.presetModal = preset;
      } else {
        this.presetModal = this.$utils.clone(this.presetModalBase);
        this.presetModal.form = JSON.stringify(this.getCurrentForm(), null, 2);
      }
      this.modal.preset = true;
    },
    showInput(target) {
      this.input_target = target;
      this.modal.input = true;
      this.shop_ids = '';
      this.selectedIdsText = this.value.map(e => e.shop_id).join('\n');
    },
    async hide() {
      this.modal.preset = false;
      await this.getFormPreset();
    },
    setBtnType(t) {
      this.presetModal.btnType = t;
    },
    checkJson() {
      try {
        JSON.parse(this.presetModal.form);
        return true;
      } catch(e) {
        return false;
      }
    },
    async addPreset() {
      if (!this.checkJson()) return alert('JSON 형식에 맞게 입력해주세요');
      if (!this.presetModal.btnName) return alert('프리셋 버튼 이름을 입력해주세요');
      // options.Size 처럼 . 이 들어간 필드때문에 mongodb 에 저장이 안돼서, string 형태로 그대로 전달한다.
      let j = await this.$api.postJson('/meta/formPreset', {type: 'add', page: this.page, ...this.presetModal, form: this.presetModal.form});
      if (j) {
        this.hide();
      }
    },
    async updatePreset() {
      if (!this.checkJson()) return alert('JSON 형식에 맞게 입력해주세요');
      if (!this.presetModal.btnName) return alert('버튼 이름을 입력해주세요');
      let j = await this.$api.postJson('/meta/formPreset', {type: 'update', ...this.presetModal, form: this.presetModal.form});
      if (j) {
        this.hide();
      }
    },
    async deletePreset() {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      let j = await this.$api.postJson('/meta/formPreset', {type: 'delete', _id: this.presetModal._id});
      if (j) {
        alert('삭제되었습니다');
        this.hide();
      }
    },
    copyFromMain() {
      this.presetModal.form = JSON.stringify(this.getCurrentForm(), null, 2);
    }
  },

}
</script>
